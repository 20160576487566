import { render, html } from "lit-html";
import { advanced } from "./advanced";

const placeholder = "for example: https://cdn.openfin.co/process-manager/app.json";

export const showForm = (manifest='', advancedMode=false) =>{
    const content = document.getElementById('content');
    const subheader = document.getElementById('subheader')
    render(html`<p>Enter your application manifest to generate a start link</p>`, subheader)
    render(html`
        <p>
            <input @input=${e => showForm(e.target.value)} type="text" id="manifest" name="manifest" value="${manifest}" placeholder="${placeholder}"/>
        </p>
        ${makeButtons(manifest)}
    `, content);
    if (advancedMode) {
        advanced();
    }
}

const makeButtons = manifest => {
    const encoded = encodeURIComponent(manifest);
    const url = `${location.href}?manifest=${encoded}`;
    return html`
    <a href="${url}" target="_blank"><button title="Launch the application now">Start App</button></a>
    <button @click=${e => copyURL(url)} title="Copy the start link to your clipboard">Copy Link</button>
    `
}

const copyURL = (startUrl) => {
    navigator.clipboard.writeText(startUrl).catch(console.error);
    return false;
}


import { render, html } from "lit-html";
import { asyncReplace } from "lit-html/directives/async-replace.js";

const adv = document.getElementById("advanced");

export const advanced = () => {
  render(
    html`<p>
        ${getHost()}<input
          @input=${(e) => validateShortcut()}
          type="text"
          id="shortcut"
          name="shortcut"
          placeholder="nice-name-goes-here"
        />
        <button
          id="saveButton"
          @click=${(e) => saveShortcut()}
          title="Save the start link"
        >
          Save
        </button>
        <button
          id="copyButton"
          @click=${(e) => copyShortcut()}
          title="Copy the start link to your clipboard"
        >
          Copy
        </button>
      </p>
      <div id="shortcutstatus"></div>`,
    adv
  );
  validateShortcut();
  document.getElementById("shortcut").focus();
};

const copyShortcut = () => {
  const url = `${getHost()}${document.getElementById("shortcut").value}`;
  navigator.clipboard.writeText(url).catch(console.error);
  return false;
};

const getHost = () => {
  let host = window.location.href;
  if (window.location.pathname && window.location.pathname.length > 1) {
    host = host.replace(window.location.pathname, "");
  }
  host = host.replace(window.location.search, "");
  return host.endsWith("/") ? host : host + "/";
};

const validateShortcut = () => {
  const manifUrl = document.getElementById("manifest");
  const shorty = document.getElementById("shortcut");
  let valid = true;
  if (manifUrl.value === "") {
    valid = false;
  }
  if (shorty.value === "") {
    valid = false;
  }

  const btn = document.getElementById("saveButton");
  if (valid) {
    shorty.classList.remove("invalid");
    btn.classList.remove("disabled");
  } else {
    btn.classList.add("disabled");
    shorty.classList.add("invalid");
  }
  return valid;
};

const saveShortcut = () => {
  if (validateShortcut()) {
    const manifUrl = document.getElementById("manifest").value;
    const shorty = document.getElementById("shortcut").value;
    console.log(`shortening url: ${manifUrl} as: ${shorty}`);
    const shortcut = { shortcut: shorty, url: manifUrl, type: "installer" };
    render(
      html`${asyncReplace(sendRequest(shortcut))}`,
      document.getElementById("shortcutstatus")
    );
  }
};

async function* sendRequest(sc) {
  const resp = await fetch("/api/", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(sc),
  });
  
  if (resp.status !== 201) {
    console.error(`error shortening url: ${sc.url} as: ${sc.shortcut} - status: ${resp.status}`);
    document.getElementById("saveButton").classList.add("disabled");
    document.getElementById("shortcut").classList.add("invalid");

    if (resp.status === 406) {
      yield `shortcut "${sc.shortcut}" already taken`;
    } else {
      yield `error saving shortcut`;
    }
  } else {
    adv.classList.add("saved");
    yield `saved`;
  }
}

import { startOpenFin } from "./start";
import { showForm } from "./form";

if (!advancedMode) {
    const params = new URLSearchParams(window.location.search);
    let manURL = params.get("manifest");
    if (!manURL || manURL === "") {
        manURL = shortcutManifest;
    }
    if (manURL) {
        startOpenFin(manURL);
    } else {
        showForm();
    }
} else {
    showForm(shortcutManifest, true);
}

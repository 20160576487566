import customProtocolDetection from 'custom-protocol-detection';
import { detect } from 'detect-browser';
import { render, html } from 'lit-html';

const browser = detect();
const os = browser.os.match(/Win/) ? 'win' : browser.os.match(/Mac/) ? 'osx' : 'unsupported';

const subhead = document.getElementById('subheader');
const content = document.getElementById('content');
const advanced = document.getElementById('advanced');

const protoDetectTimeouts = {
    chromeAndIOS: 2500,
    legacyIE:     2500,
    safari:       2500
}

function stats(manifest, status) {
    fetch(`/stats?manifest=${encodeURIComponent(manifest)}&status=${status}&ua=${encodeURIComponent(browser.os)}`);
}

let manifest;

export const startOpenFin = (manifestUrl) => {
    manifest = manifestUrl
    const delim = (manifest.indexOf('?')<0) ? '?' : '&';
    const finsurl = `${manifest.replace(/^http/, "fin")}${delim}$$_rvmStealFocus=true`;
    if (os !== 'unsupported') {
        render(html`<p>Click <b>Open OpenFin RVM</b> on the dialog shown by your browser</p>`, subhead)
        render(installerMessage(html`If you don't see a dialog, click <b>Download Installer</b> below.`, 'Download Installer'), content);

        try {
            customProtocolDetection(finsurl, () => {
                console.log(`fins test failed, application ${manifest}.`);
                stats(manifest, 'failure')
            }, () => {
                console.log(`fins test success, application ${manifest}.`);
                stats(manifest, 'success')
            }, () => {
                console.log(`fins test unsupported, application ${manifest}.`);
                stats(manifest, 'unsupported browser')
            }, protoDetectTimeouts);
        } catch(e) {
            console.error(`error running custom protocol`, e)
        }
    } else {
        unsupported()
    }
}

function unsupported() {
    stats(manifest, 'unsupported os')
    render(html`OpenFin Start is not supported on this operating system`, subhead);
    render(html`<p>${installerButton('Download Installer')}</p>`,content);
    cliMessage();
}

function cliMessage() {
    render(html`<p>You may also download the <a href="https://www.npmjs.com/package/openfin-cli">OpenFin CLI</a> and run</p>
        <div class="cli-msg">
        <div class="cli-wrap"><div class="cli-code">openfin -l -c \"${manifest}\"</div></div>
    </div>`,advanced);
}

function installerMessage(message, linktext) {
    return html`<p>
        ${message}<br/><br/><br/>
        ${installerButton(linktext)}
    </p>`
}

function installerButton(text) {
    const installerUrl = `https://install.openfin.co/download/?os=${os}&config=${encodeURIComponent(manifest)}`;
    return html`<a href="${installerUrl}" target="_blank" ><button>${text}</button></a>`
}
